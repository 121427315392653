import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import contactImg from "../assets/img/rickroll.gif";

export const Footer = () => {
  return (
    <footer className="footer" style={{ padding: '20px 0', backgroundColor: '#f8f9fa', color: '#343a40' }}>
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={4} className="text-center">
            <img src={logo} alt="Company Logo" className="footer-logo" />
          </Col>
          <Col size={12} md={4} className="text-center">
            <img src={contactImg} alt="Animated contact image" className="footer-image" />
          </Col>
          <Col size={12} md={4} className="text-center">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/bram-kuper/" aria-label="LinkedIn Profile">
                <img src={navIcon1} alt="LinkedIn Icon" />
              </a>
              <a href="https://github.com/duttchhy" aria-label="GitHub Profile">
                <img src={navIcon2} alt="GitHub Icon" />
              </a>
            </div>
            <p>Bram 'Duttchhy' 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
